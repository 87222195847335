import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Dialog, DialogTitle, Divider, DialogContent, IconButton, Link, Alert, Typography, Box, } from '@mui/material';
import { useDisclosure, useDropzone, useUploadHealthCheckFile } from 'shared';
import { FileTypeInfo } from './FileTypeInfo';
import { FlexCol, AppDropzone, Flex, AppButton } from '../../../components';
/**
 * 健康診断書をアップロードするためのダイアログコンポーネント
 */
export const UploadHealthCheckDialog = ({ disabled, label, onUploaded, showAlert = true, staff, surveyUserId, user, }) => {
    var _a, _b, _c, _d, _e, _f;
    const { getButtonProps, getDisclosureProps, onClose } = useDisclosure();
    const { acceptedFiles, getInputProps, getRootProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
    });
    const { handleUploadHealthCheckFile } = useUploadHealthCheckFile({
        file: acceptedFiles[0],
        onUploaded: () => {
            onUploaded === null || onUploaded === void 0 ? void 0 : onUploaded();
            onClose();
            acceptedFiles.splice(0, 1);
        },
        uploaderId: (_a = staff === null || staff === void 0 ? void 0 : staff.id) !== null && _a !== void 0 ? _a : user === null || user === void 0 ? void 0 : user.id,
        atndUser: user
            ? {
                email: user.email,
                id: '',
                atnd_user_id: user.id,
                birthday: (_b = user.birthday) !== null && _b !== void 0 ? _b : undefined,
                first_name: (_c = user.first_name) !== null && _c !== void 0 ? _c : undefined,
                last_name: (_d = user.last_name) !== null && _d !== void 0 ? _d : undefined,
                first_name_kana: (_e = user.first_name_kana) !== null && _e !== void 0 ? _e : undefined,
                last_name_kana: (_f = user.last_name_kana) !== null && _f !== void 0 ? _f : undefined,
                sex: user.sex,
            }
            : undefined,
        uploadType: 'pdf',
        surveyUserId,
    });
    return (_jsxs(_Fragment, { children: [label ? (_jsx(AppButton, Object.assign({ variant: "contained" }, getButtonProps(), { disabled: disabled, children: _jsxs(Flex, { gap: 1, alignItems: "center", children: [_jsx(CloudUploadIcon, { fontSize: "small" }), label] }) }))) : (_jsx(IconButton, Object.assign({}, getButtonProps(), { disabled: disabled, children: _jsx(CloudUploadIcon, {}) }))), _jsxs(Dialog, Object.assign({}, getDisclosureProps(), { "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", PaperProps: {
                    sx: {
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                    },
                }, fullWidth: true, children: [_jsx(DialogTitle, { id: "dialog-title", variant: "h6", fontWeight: 600, py: 2.5, children: "\u5065\u5EB7\u8A3A\u65AD\u66F8\u306E\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9" }), _jsx(Divider, {}), _jsx(DialogContent, { children: _jsxs(FlexCol, { gap: 3, children: [showAlert && (_jsxs(Alert, { severity: "warning", sx: { '& a': { color: 'inherit', fontWeight: 600 } }, children: [_jsxs(Box, { pl: 1, children: ["\u30B9\u30DE\u30FC\u30C8\u30D5\u30A9\u30F3\u304B\u3089\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3067\u304D\u306A\u3044\u5834\u5408\u306F\u3001", _jsx(Link, { href: "https://forms.gle/LPMtkDMJ1kZWcwki7", target: "_blank", rel: "noopener noreferrer", children: "\u3053\u3061\u3089\u306E\u30D5\u30A9\u30FC\u30E0" }), ` からご連絡ください`] }), _jsxs("ul", { style: {
                                                paddingTop: 1,
                                                listStyleType: 'circle',
                                                marginLeft: '-25px',
                                            }, children: [_jsx("li", { children: _jsx(Typography, { variant: "body2", children: "\uFF11\u3064\u306E\u30D5\u30A1\u30A4\u30EB\u306B\u307E\u3068\u3081\u3066\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002" }) }), _jsx("li", { children: _jsx(Typography, { variant: "body2", children: `１つのファイルにできない場合は(お問い合わせ先フォーム)までご連絡ください。` }) })] })] })), _jsx(FileTypeInfo, {}), _jsx(AppDropzone, { acceptedFiles: acceptedFiles, getInputProps: getInputProps, getRootProps: getRootProps, isDragActive: isDragActive, upload: handleUploadHealthCheckFile })] }) })] }))] }));
};
