var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { LINK_COLOR, LINK_HOVER_COLOR } from './constants';
export const ExternalLink = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx("a", Object.assign({}, props, { style: {
            textDecoration: 'none',
            color: LINK_COLOR,
            transition: 'color 0.3s ease',
        }, children: _jsx(Typography, { component: "span", sx: {
                '&:hover': {
                    color: LINK_HOVER_COLOR,
                },
            }, children: children }) })));
};
