var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
import { refreshAccessToken } from '../cutsom-api';
import { getAuthIdToken } from '../firebase';
import { getHospitalIdFromCookie, hospitalAccessToken, hospitalRefreshToken, removeCookie, } from '../utils';
// 末尾のスラッシュを追加する関数
export var addTrailingSlashToURL = function (config) {
    var hasTrailingSlash = !!config.url && config.url[config.url.length - 1] === '/';
    if (hasTrailingSlash) {
        return config;
    }
    else {
        var withTrainingSlash = "".concat(config.url, "/");
        return __assign(__assign({}, config), { url: withTrainingSlash });
    }
};
// 認証エラーのカスタムエラーハンドリング
export var customErrorResponse = function (axios, error) { return __awaiter(void 0, void 0, void 0, function () {
    var originalRequest, isAuthorizationError, alreadyRetried, clinicAlias, hospitalId, refreshToken, newIdToken;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                originalRequest = error.config;
                isAuthorizationError = [401, 403].includes((_a = error.response) === null || _a === void 0 ? void 0 : _a.status);
                if (!isAuthorizationError)
                    return [2 /*return*/, Promise.reject(error)];
                alreadyRetried = originalRequest._retry;
                if (!alreadyRetried) return [3 /*break*/, 1];
                removeCookie('token');
                return [2 /*return*/, Promise.reject(error)];
            case 1:
                originalRequest._retry = true;
                clinicAlias = getClinicAliasFromUrl();
                hospitalId = getHospitalIdFromCookie(clinicAlias);
                if (!hospitalId) return [3 /*break*/, 5];
                refreshToken = hospitalRefreshToken.get();
                if (!refreshToken) return [3 /*break*/, 3];
                console.info('refresh HospitalToken', refreshToken);
                return [4 /*yield*/, refreshAccessToken(refreshToken, axios, originalRequest)];
            case 2:
                _b.sent();
                return [2 /*return*/, axios.request(originalRequest)];
            case 3:
                hospitalAccessToken.remove();
                hospitalRefreshToken.remove();
                return [2 /*return*/, Promise.reject(error)];
            case 4: return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, getAuthIdToken()];
            case 6:
                newIdToken = _b.sent();
                console.info('refresh FirebaseToken', newIdToken);
                if (newIdToken) {
                    originalRequest.headers.Authorization = "Bearer ".concat(newIdToken);
                    return [2 /*return*/, axios.request(originalRequest)];
                }
                else {
                    removeCookie('token');
                    return [2 /*return*/, Promise.reject(error)];
                }
                _b.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}); };
export var buildAxiosConfigWithAuth = function (config, token, hospitalId) {
    var _a, _b;
    var newConfig = addTrailingSlashToURL(config);
    return __assign(__assign({}, newConfig), { headers: __assign(__assign(__assign({}, newConfig.headers), { Authorization: (_b = (_a = newConfig.headers) === null || _a === void 0 ? void 0 : _a['Authorization']) !== null && _b !== void 0 ? _b : (token ? "Bearer ".concat(token) : undefined) }), (hospitalId && { 'Hospital-Id': hospitalId })) });
};
export var getClinicAliasFromUrl = function () {
    var paths = window.location.href.split('/');
    var clinicAliasIndex = 3;
    return paths.length >= clinicAliasIndex ? paths[clinicAliasIndex] : undefined;
};
// APIリクエストのラップ用関数。ジェネリック型`T`を使ってレスポンスの型を指定。
export var fetchApi = function (request, handleError) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, request().catch(function (error) {
                handleError(error);
                return Promise.reject(error);
            })];
    });
}); };
export var isAxiosError = function (error) {
    return Axios.isAxiosError(error);
};
